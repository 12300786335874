*
{
	box-sizing: border-box;
}

body
{
	margin: 0;
	padding: 0;
	color: #111;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: white;
}

.site-header
{
	height: 60px;
	border-bottom: 1px solid rgb(236, 238, 241);

	.logo-display
	{
		width: 100%;
		height: 48px;
		max-width: 768px;
		margin: 9px auto;
		background: url('./view/img/logo-osaccounts.jpg') left center no-repeat;
		background-size: contain;
	}

	@media screen and (max-width: 768px) {
		.logo-display
		{
			background-position: center;
		}
	}
}

.site-content
{
	background-color: #f6f8fa;
	padding: 48px 0 64px;
}

body,
button,
select
{
	font-family: 'Nunito Sans', -apple-system, blinkmacsystemfont, 'Segoe UI', Roboto, helvetica, arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
}

h1, h2, h3, h4
{
	font-weight: 600;
}

h1, h2, h3, h4, p
{
	margin: 0 0 4px;
	padding: 0;
}

h1
{
	font-size: 54px;
	font-weight: 700;
	text-align: center;
	margin: 0 0 40px;
	padding: 0 20px;
}

p
{
	font-size: 16px;
	margin-bottom: 20px;
	padding: 0 20px;
}

a
{
	color: #4d5968;
}

@media screen and (max-width: 768px)
{
	h1
	{
		font-size: 48px;
	}
}

@media screen and (max-width: 480px)
{
	h1
	{
		font-size: 36px;
	}

	p
	{
		font-size: 14px;
	}
}

// CSS styles derived from www.osaccounts.com

.header-contact--mobile,
.site-footer
{
	background-color: white;
}

.header-contact--mobile {
	line-height: 37px;
	font-size: 15px;
	border-bottom: 1px solid rgb(236, 238, 241);
	text-align: center;
	top: 0;
	width: 100%;

	.website-link {
		float: left;
		display: none;
	}

	.fa {
		padding-left: 1rem;
		padding-right: 4px;
	}

	a {
		text-decoration: none;
		white-space: nowrap;
	}
}

@media (min-width: 896px) {
	.header-contact--mobile {
		text-align: right;
		padding-right: 1rem;
	}
	#header-contact {
		display: none;
	}
}

@media (min-width: 768px) {
	.header-contact--mobile {
		.website-link {
			display: inline-block;
		}
	}
}

@media (min-width: 768px) and (max-width: 896px) {
	.header-contact--mobile {
		text-align: right;
		padding-right: 1rem;

		.website-link {
			display: inline-block;
		}
	}
}

.site-footer {
	position: relative;
	padding: 20px 20px;
	font-size: 12px;
	line-height: 22px;
	text-align: center;
	border-top: 1px solid rgb(236, 238, 241);
	width: 100%;
	color: #4d5968;
}
