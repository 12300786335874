.AssessView,
.AdminView
{
	h1
	{
		margin-bottom: 20px;
	}

	> div > p
	{
		text-align: center;
	}

	.traffic-light
	{
		float: left;
		margin-right: 12px;
	}

	.summary-display,
	.breakdown-display
	{
		background-color: white;
		margin-bottom: 20px;
		border-radius: 0;
	}

	.summary-display
	{
		padding: 20px;
		margin-top: 50px;

		h2
		{
			line-height: 120px;
			font-size: 120px;
			text-align: center;
		}

		h3
		{
			line-height: 40px;
			font-size: 30px;
			text-align: center;
		}

		@media screen and (max-width: 420px) {
			h2
			{
				font-size: 100px;
			}
		}

		@media screen and (max-width: 320px) {
			h2
			{
				font-size: 80px;
			}
		}
	}

	.breakdown-display
	{
		padding: 0;
		clear: both;
		margin-bottom: 12px;

		p, h4
		{
			line-height: 24px;
			padding: 0;
			margin: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		h4
		{
			padding-top: 3px;
			font-size: 18px;
		}

		p
		{
			font-size: 14px;
		}

		.result-display
		{
			padding: 20px;
			min-height: 54px;
			border-bottom: 1px solid #eceef1;

			&:first-child
			{
				margin-top: 10px;
			}

			&:last-child
			{
				border-bottom: none;
			}
		}
	}
}
