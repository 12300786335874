.LoginView
{
	text-align: center;

	.logo-osassess
	{
		display: inline-block;
		width: 42px;
		height: 42px;
		background: url('./img/logo-osassess.png') center no-repeat;
		background-size: contain;
		margin-right: 20px;
		vertical-align: baseline;
	}

	@media screen and (max-width: 768px) {
		.logo-osassess
		{
			display: block;
			width: 96px;
			height: 96px;
			margin: 0 auto 20px;
		}
	}

	.xero-sso-link-display
	{
		margin-top: 50px;
		margin-bottom: 40px;

		.xero-sso-link
		{
			min-width: 280px;
		}
	}
}
